









import Vue from "vue";
export default Vue.extend({
  name: "orange-arrow-left",
});
