


























































































































































import Vue from "vue";
import router from "../router/index";
import { Auth } from "aws-amplify";
import ChangeDateDialog from "../components/ChangeDateDialog.vue";
import store from "@/store";
import { debugLog } from "@/lib/debug_util";
interface DataTimestamp {
  date: string;
  hour: string;
  minute: string;
}
interface ChangeDateResponse {
  action: string;
  datetime: DataTimestamp;
  update: boolean;
  message: string;
}
export default Vue.extend({
  name: "header-view",
  components: { ChangeDateDialog },
  data() {
    return {
      headerColor: "white",
      email: "xxxxx@xxx.co.jp",
      imsi: "999999999999999",
      switchPageDetail: true,
      displayDataTime: {
        date: "",
        hour: "",
        minute: "",
      },
      clearDisplayDataTime: {
        date: "",
        hour: "",
        minute: "",
      },
      colorSummaryIcon: "grey",
      colorDetailIcon: "primary",
      classFontSummary: "text-subtitle-1 text--disabled",
      classFontDetail: "text-h6",
      isMobile: false,
      isVisibleChangeDataDialog: false,
      isAutoUpdate: true,
      drawer: false,
      isSnackBarDisplayingPastData: false,
      isVisibleSummaryPage: true,
    };
  },
  created() {
    this.getEmail();
    this.getImsi();
    this.changeSummaryInvisible();
  },
  computed: {
    textDisplayTime(): string {
      return this.formatDateTime(
        store.getters.displayDataTime.date,
        store.getters.displayDataTime.hour,
        store.getters.displayDataTime.minute
      );
    },
    positionTopSnackbar(): boolean {
      let resultValue = false;
      if (this.$route.path === "/dashboard/detail") {
        resultValue = false;
      } else {
        resultValue = true;
      }
      return resultValue;
    },
    isDownloadView(): boolean {
      let resultValue = false;
      if (this.$route.path === "/dashboard/download") {
        resultValue = true;
      }
      return resultValue;
    },
  },
  watch: {
    switchPageDetail: {
      handler: function () {
        if (this.switchPageDetail) {
          this.colorDetailIcon = "primary";
          this.classFontDetail = "text-h6 font-weight-black";
          this.colorSummaryIcon = "grey";
          this.classFontSummary = "text-subtitle-1 text--disabled";
        } else {
          this.colorDetailIcon = "grey";
          this.classFontDetail = "text-subtitle-1 text--disabled";
          this.colorSummaryIcon = "primary";
          this.classFontSummary = "text-h6 font-weight-black";
        }
      },
    },
    isAutoUpdate: {
      handler: function () {
        this.isSnackBarDisplayingPastData = !this.isAutoUpdate;
      },
    },
  },
  mounted() {
    this.isAutoUpdate = store.getters.isAutoUpdate;
    this.displayDataTime = store.getters.displayDataTime;
  },
  methods: {
    async getEmail() {
      const user = await Auth.currentAuthenticatedUser();
      this.email = user.attributes.email;
    },
    async getImsi() {
      this.imsi = store.getters.companies_plant.imsi;
    },
    async changeSummaryInvisible() {
      const company_id = store.getters.company_id;
      if (company_id == "K285" || company_id == "SUSE") {
        debugLog("K285、SUSEの場合はサマリーページを非表示");
        this.isVisibleSummaryPage = false;
      }
    },
    moveToPage(path: string): void {
      router.push(path).catch((err) => {
        // 同じURLへアクセスした場合のエラー以外をエラーとする
        if (err.name != "NavigationDuplicated") {
          console.error(err);
        }
      });
    },
    moveToDownloadPage(): void {
      if (this.$route.path !== "/dashboard/download") {
        this.moveToPage("/dashboard/download");
      }
    },
    moveToGraphPage(): void {
      if (this.$route.path !== "/dashboard/graph") {
        store.commit("setShowDatasetItems", []);
        this.moveToPage("/dashboard/graph");
      }
    },
    moveToSummaryPage(): void {
      if (this.$route.path !== "/dashboard/summary") {
        this.switchPageDetail = false;
        this.moveToPage("/dashboard/summary");
      }
    },
    moveToDetailPage(): void {
      if (this.$route.path !== "/dashboard/detail") {
        this.switchPageDetail = true;
        this.moveToPage("/dashboard/detail");
      }
    },
    switchPage(): void {
      if (this.switchPageDetail) {
        this.moveToDetailPage();
      } else {
        this.moveToSummaryPage();
      }
    },
    async signOut() {
      try {
        const company_id = store.getters.company_id;
        let appendUrl = "";
        if (company_id != null && company_id.length >= 1) {
          appendUrl = "/" + company_id;
        }
        await Auth.signOut();
        router.push("/login" + appendUrl);
        store.commit("setIsAutoUpdate", true);
        store.commit(
          "setDisplayDataTime",
          Object.assign({}, this.clearDisplayDataTime)
        );
        store.commit("setNextLoadingDisplay", true);
      } catch (error) {
        console.log("error signing out: ", error);
      }
    },
    formatDateTime(date: string, hour: string, minute: string): string {
      let resultString = "";
      resultString = date + " " + hour + ":" + minute;
      return resultString;
    },
    openChangeDateMenu(): void {
      if (
        this.$route.path === "/dashboard/detail" ||
        this.$route.path === "/dashboard/summary" ||
        this.$route.path === "/dashboard/graph"
      ) {
        this.isAutoUpdate = store.getters.isAutoUpdate;
        Object.assign(this.displayDataTime, store.getters.displayDataTime);
        this.isVisibleChangeDataDialog = true;
      }
    },
    responseChangeDateDialog(response: ChangeDateResponse): void {
      this.isVisibleChangeDataDialog = false;
      if (response.action === "commit") {
        this.isAutoUpdate = response.update;
        // 自動更新状態が変更されている場合は、次の表示更新でローディング表示をさせる
        const beforeIsAutoUpdate = store.getters.isAutoUpdate;
        if (beforeIsAutoUpdate != this.isAutoUpdate) {
          store.commit("setNextLoadingDisplay", true);
        }
        // 自動更新状態を変更する（これを契機に自動更新時の表示更新が行われる）
        store.commit("setIsAutoUpdate", this.isAutoUpdate);
        if (!this.isAutoUpdate) {
          // 自動更新出ない場合は、指定された日時を記憶する
          const changedDataTime = Object.assign({}, response.datetime);
          store.commit("setOnceUpdateDataTime", changedDataTime);
          // １度だけの更新状態を変更する（これを契機に自動更新でない場合の表示更新が行われる）
          store.commit("setIsOnceUpdate", true);
        }
      }
    },
  },
});
