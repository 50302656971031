


































































































































































import Vue from "vue";
import AlertRecommendDialog from "../components/AlertRecommendDialog.vue";
import CustomList from "../components/tankComponent/CustomList.vue";
import Score from "../components/Score.vue";
import { getIconInfo } from "../lib/get_icon_info";
import { bus } from "../main";
import { debugLog } from "@/lib/debug_util";
import WebApiModel from "@/apis/web_api";
import {
  getDetailData,
  getDisplayScore,
  getPlantAlertRecommend,
} from "@/lib/web_api_util";
import store from "@/store";
import {
  DetailFacility,
  DetailDisplayData,
  DisplayDataTime,
  AlertsInfo,
  AlertRecommend,
} from "@/types";
import { mapState } from "vuex";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Scores } from "../types";

export default Vue.extend({
  name: "Mobile",
  components: { CustomList, AlertRecommendDialog, Score },
  data() {
    return {
      ///////////////////////////////////////////////////////////////
      // APIアクセス関連
      ///////////////////////////////////////////////////////////////
      webApiModel: {} as WebApiModel,

      ///////////////////////////////////////////////////////////////
      // エラー表示関連
      ///////////////////////////////////////////////////////////////
      errorAlertMessage: "",
      showErrorDialog: false,
      errorDialogMessage: "",
      errorDialogType: "",

      ///////////////////////////////////////////////////////////////
      // 画面表示関連
      ///////////////////////////////////////////////////////////////
      // ローディング表示用
      isLoadingDisplay: false,

      // タイマー設定用の変数
      timeOutId: 0,

      // スコアダイアログの表示制御用
      scoreDialog: false,

      // アラートダイアログの表示制御用
      alertDialog: false,
      alertMessage: "",
      alertRecommend: {} as AlertRecommend,

      // アラート詳細ダイアログの表示制御用
      isVisibleAlertRecommendDialog: false,

      // 画面表示用データの格納場所
      displayData: {
        facilities: [] as Array<DetailFacility>,
        alerts_info: [] as Array<AlertsInfo>,
        scores: [] as Scores[],
      } as DetailDisplayData,

      // アイコン表示用
      iconObject: getIconInfo(),
    };
  },
  created() {
    bus.$emit("background", "image1");
    // 予めdayjsのutcプラグインを有効化しておく
    dayjs.extend(utc);
    // APIアクセス用ライブラリを作成
    this.webApiModel = new WebApiModel();
  },

  computed: mapState(["isAutoUpdate", "isOnceUpdate"]),

  mounted() {
    store.commit("setNextLoadingDisplay", true);
    this.getDisplayData();
  },

  watch: {
    isAutoUpdate() {
      debugLog("自動更新？", store.getters.isAutoUpdate);
      // 自動更新に変更した場合は、画面表示データ取得を行う
      if (store.getters.isAutoUpdate === true) {
        debugLog("自動更新を開始します", store.getters.isAutoUpdate);
        this.getDisplayData();
      }
    },
    isOnceUpdate() {
      debugLog("日付指定更新？", store.getters.isOnceUpdate);
      // １度だけの画面表示データ取得を行う（自動更新出ない場合）
      if (store.getters.isOnceUpdate === true) {
        debugLog("日付指定更新を開始します", store.getters.isOnceUpdate);
        this.getDisplayData();
        store.commit("setIsOnceUpdate", false);
      }
    },
  },

  beforeDestroy() {
    // 自動更新のためのタイマーを止める
    clearTimeout(this.timeOutId);
  },

  methods: {
    // アラート表示
    alertOpen(): void {
      this.alertDialog = true;
    },

    // スコア表示
    scoreOpen(): void {
      this.scoreDialog = false;
      this.$nextTick(() => (this.scoreDialog = true));
    },

    // 画面表示データを取得する
    async getDisplayData(): Promise<void> {
      debugLog("getDisplayData");
      // 自動更新で初回、および自動更新でない場合はローディング表示オンにする
      if (
        store.getters.nextLoadingDisplay == true ||
        store.getters.isAutoUpdate == false
      ) {
        debugLog("ローディング表示オン");
        this.isLoadingDisplay = true;
        store.commit("setNextLoadingDisplay", false);
      }
      // 自動更新タイマーを停止する
      clearTimeout(this.timeOutId);

      // 詳細画面表示用データを取得する
      const responsePlantsData = await getDetailData(
        this.webApiModel,
        this.displayData
      );
      await getDisplayScore(this.webApiModel, this.displayData);
      // エラーの場合はエラーを表示し処理を中断する
      if (responsePlantsData === undefined) {
        // ローディング表示オフ
        this.isLoadingDisplay = false;
        if (store.getters.isAutoUpdate === true) {
          // 自動更新の場合はエラーをアラート表示する
          this.errorAlertMessage = "情報の取得に失敗しました";
        } else {
          // 自動更新でない場合は(ヘッダ内の)データ表示日時を
          // 確認する。これが空でない場合は、以下の場合に備えて
          // store内のonceUpdateDataTimeへ値をコピーする。
          // ・再読み込み操作をされた時
          // ・別画面へ遷移後にメイン画面へ戻る操作をされた時
          // ↓
          // いずれもonceUpdateDataTimeの日時を元にAPIを
          // アクセスしてデータ取得するので、ヘッダ内の表示と
          // APIアクセスの日時が一致していないと不自然である。
          const displayDataTime: DisplayDataTime =
            store.getters.displayDataTime;
          if (
            displayDataTime.date !== "" &&
            displayDataTime.hour !== "" &&
            displayDataTime.minute !== ""
          ) {
            store.commit("setOnceUpdateDataTime", displayDataTime);
          }
          // 自動更新でない場合はエラーポップアップを表示する
          this.errorDialogType = "error";
          this.errorDialogMessage = "情報の取得に失敗しました";
          this.showErrorDialog = true;
        }
        debugLog("getDisplayData error");
      } else {
        // 時刻オフセットを考慮したdayjsのオブジェクトを作成する
        const timeOffset: number = store.getters.companies_plant.time_offset;
        const recordTime = dayjs(this.displayData.record_time).utcOffset(
          timeOffset
        );
        debugLog("recordTime with offset:", recordTime.format());
        // データ表示日時を設定する
        const displayDataTime = {} as DisplayDataTime;
        displayDataTime.date = dayjs(recordTime).format("YYYY-MM-DD");
        displayDataTime.hour = dayjs(recordTime).format("HH");
        displayDataTime.minute = dayjs(recordTime).format("mm");
        store.commit("setDisplayDataTime", displayDataTime);

        // 成功すればエラーのアラート表示を消去
        this.errorAlertMessage = "";
        // ローディング表示オフ
        this.isLoadingDisplay = false;
      }
      // エラー発生有無によらず、自動更新の場合は、自動更新用のタイマーを設定する
      if (store.getters.isAutoUpdate === true) {
        // 画面更新間隔(秒)をstoreから取得
        const refresh_seconds = store.getters.refresh_seconds;
        this.timeOutId = window.setTimeout(() => {
          // タイマー発火時、自動更新の場合は画面表示更新を行う
          if (store.getters.isAutoUpdate === true) {
            debugLog("自動更新実施");
            this.getDisplayData();
          }
        }, refresh_seconds * 1000);
      }
    },

    // アラートアイテムをクリックした時
    async clickAlertItem(item: AlertsInfo, index: number): Promise<void> {
      debugLog("click alert", "index=" + index, item.alert_message);

      // アラートレコメンド追加前の過去データを表示している場合、plc_addressは空である
      // その場合はアラートレコメンドを取得できないため、
      // メッセージポップアップを表示し処理を中断する
      if (item.plc_address == null || item.plc_address.length === 0) {
        // エラーポップアップを表示する
        this.errorDialogType = "info";
        this.errorDialogMessage = "この時刻のアラート詳細は登録されていません";
        this.showErrorDialog = true;
        return;
      }

      // ローディング表示オン
      this.isLoadingDisplay = true;
      // IMSIとPLCアドレスを指定してアラートレコメンドを取得する
      const imsi = store.getters.companies_plant.imsi;
      const response = await getPlantAlertRecommend(
        this.webApiModel,
        imsi,
        item.plc_address
      );

      // エラーの場合はエラーポップアップを表示し処理を中断する
      if (response === undefined) {
        // エラーポップアップを表示する
        this.errorDialogType = "error";
        this.errorDialogMessage =
          "プラントアラートレコメンドの取得に失敗しました";
        this.showErrorDialog = true;
        // ローディング表示オフ
        this.isLoadingDisplay = false;
        return;
      }
      // データがない場合はエラーポップアップを表示し処理を中断する
      else if (
        response.records === undefined ||
        response.records.length === 0
      ) {
        // エラーポップアップを表示する
        this.errorDialogType = "info";
        this.errorDialogMessage = "アラート詳細が登録されていません";
        this.showErrorDialog = true;
        // ローディング表示オフ
        this.isLoadingDisplay = false;
        return;
      }

      // アラート詳細ダイアログを表示する
      this.alertMessage = item.alert_message;
      this.alertRecommend = response.records[0];
      this.isVisibleAlertRecommendDialog = true;
      // ローディング表示オフ
      this.isLoadingDisplay = false;
    },

    // アラート詳細ダイアログを閉じる
    responseAlertRecommendDialog() {
      this.isVisibleAlertRecommendDialog = false;
    },

    // エラーポップアップを閉じる
    resetErrorDialog(): void {
      this.errorDialogMessage = "";
      this.showErrorDialog = false;
    },
  },
});
