import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Login from "../views/SignIn.vue";
import DashBoard from "../views/DashBoard.vue";
import Detail from "../views/Detail.vue";
import Summary from "../views/Summary.vue";
import Download from "../views/Download.vue";
import Graph from "../views/Graph.vue";
import MobileDashBoard from "../views/MobileDashBoard.vue";
import Mobile from "../views/Mobile.vue";
import MobileDataView from "../views/MobileDataView.vue";
import MobileGraph from "../views/MobileGraph.vue";

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { requiredAuth: false },
  },
  {
    path: "/",
    redirect: "/dashboard",
  },
  {
    path: "/login/:companyId",
    name: "CompanyLogin",
    component: Login,
    meta: { requiredAuth: false },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: DashBoard,
    redirect: {
      name: "Login",
    },
    meta: { requiredAuth: false },
    children: [
      {
        path: "detail",
        name: "Detail",
        component: Detail,
        meta: { requiredAuth: true },
      },
      {
        path: "summary",
        name: "Summary",
        component: Summary,
        meta: { requiredAuth: true },
      },
      {
        path: "download",
        name: "Download",
        component: Download,
        meta: { requiredAuth: true },
      },
      {
        path: "graph",
        name: "Graph",
        component: Graph,
        meta: { requiredAuth: true },
      },
    ],
  },
  {
    path: "/mobiledashboard",
    name: "mobiledashboard",
    component: MobileDashBoard,
    redirect: {
      name: "Login",
    },
    meta: { requiredAuth: false },
    children: [
      {
        path: "mobile",
        name: "Mobile",
        component: Mobile,
        meta: { requiredAuth: true },
      },
      {
        path: "dataview",
        name: "DataView",
        component: MobileDataView,
        meta: { requiredAuth: true },
      },
      {
        path: "mobilegraph",
        name: "MobileGraph",
        component: MobileGraph,
        meta: { requiredAuth: true },
      },
    ],
  },
  {
    path: "*",
    redirect: {
      name: "Login",
    },
  },
];

const router: VueRouter = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
