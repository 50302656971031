





































































import Vue from "vue";
import { DetailItem } from "@/types";

export default Vue.extend({
  name: "custom-list",
  props: {
    dataList: { type: Array, default: () => [] },
  },
  data() {
    return { okColor: "#58AC31" };
  },
  methods: {
    getIconColor(data: DetailItem) {
      if (data.onoff && data.value === true) {
        return data.colorOn;
      } else if (data.onoff && data.value === false) {
        return data.colorOff;
      } else {
        return "#58AC31";
      }
    },
  },
});
