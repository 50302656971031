








import Vue from "vue";
export default Vue.extend({
  name: "blue-short-arrow",
});
