































































































































































import Vue from "vue";
import router from "../router/index";
import SingleTank from "../components/tankComponent/SingleTank.vue";
import NavigationDrawer from "../components/NavigationDrawer.vue";
import Flow from "../components/Flow.vue";
import BlueLongArrow from "../components/summaryComponent/BlueLongArrow.vue";
import BlueShortArrow from "../components/summaryComponent/BlueShortArrow.vue";
import OrangeArrowDown from "../components/summaryComponent/OrangeArrowDown.vue";
import OrangeArrowRight from "../components/summaryComponent/OrangeArrowRight.vue";
import OrangeArrowLeft from "../components/summaryComponent/OrangeArrowLeft.vue";
import { bus } from "../main";
import { debugLog } from "@/lib/debug_util";
import WebApiModel from "@/apis/web_api";
import { getSummaryData, getDisplayScore } from "@/lib/web_api_util";
import store from "@/store";
import {
  DisplayDataTime,
  SummaryDisplayData,
  SummaryFacilities,
  SummaryFlow,
  Scores,
  AlertsInfo,
} from "@/types";
import { mapState } from "vuex";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

export default Vue.extend({
  name: "Summary",
  components: {
    SingleTank,
    NavigationDrawer,
    Flow,
    BlueLongArrow,
    BlueShortArrow,
    OrangeArrowDown,
    OrangeArrowRight,
    OrangeArrowLeft,
  },
  data() {
    return {
      ///////////////////////////////////////////////////////////////
      // APIアクセス関連
      ///////////////////////////////////////////////////////////////
      webApiModel: {} as WebApiModel,

      ///////////////////////////////////////////////////////////////
      // エラー表示関連
      ///////////////////////////////////////////////////////////////
      errorSnackBarMessage: "",
      showErrorDialog: false,
      errorDialogMessage: "",

      ///////////////////////////////////////////////////////////////
      // 画面表示関連
      ///////////////////////////////////////////////////////////////
      // ローディング表示用
      isLoadingDisplay: false,

      // タイマー設定用の変数
      timeOutId: 0,

      // 画面表示用データの格納場所
      displayData: {
        facilities: {} as SummaryFacilities,
        flows: [] as Array<SummaryFlow>,
        alerts: [] as Array<string>,
        alerts_info: [] as Array<AlertsInfo>,
        scores: [] as Scores[],
        record_time: "",
      } as SummaryDisplayData,
    };
  },
  created() {
    bus.$emit("background", "image2");
    // 予めdayjsのutcプラグインを有効化しておく
    dayjs.extend(utc);
    // APIアクセス用ライブラリを作成
    this.webApiModel = new WebApiModel();
  },
  computed: {
    ...mapState(["isAutoUpdate", "isOnceUpdate"]),
    isShowErrorSnackBarMessage() {
      if (this.errorSnackBarMessage.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    store.commit("setNextLoadingDisplay", true);
    this.getDisplayData();
  },
  watch: {
    isAutoUpdate() {
      debugLog("自動更新？", store.getters.isAutoUpdate);
      // 自動更新に変更した場合は、画面表示データ取得を行う
      if (store.getters.isAutoUpdate === true) {
        debugLog("自動更新を開始します", store.getters.isAutoUpdate);
        this.getDisplayData();
      }
    },
    isOnceUpdate() {
      debugLog("日付指定更新？", store.getters.isOnceUpdate);
      // １度だけの画面表示データ取得を行う（自動更新出ない場合）
      if (store.getters.isOnceUpdate === true) {
        debugLog("日付指定更新を開始します", store.getters.isOnceUpdate);
        this.getDisplayData();
        store.commit("setIsOnceUpdate", false);
      }
    },
  },
  beforeDestroy() {
    // 自動更新のためのタイマーを止める
    clearTimeout(this.timeOutId);
  },
  methods: {
    moveToPage(path: string): void {
      router.push(path);
    },
    // 画面表示データを取得する
    async getDisplayData(): Promise<void> {
      debugLog("getDisplayData");
      // 自動更新で初回、および自動更新でない場合はローディング表示オンにする
      if (
        store.getters.nextLoadingDisplay == true ||
        store.getters.isAutoUpdate == false
      ) {
        debugLog("ローディング表示オン");
        this.isLoadingDisplay = true;
        store.commit("setNextLoadingDisplay", false);
      }
      // 自動更新タイマーを停止する
      clearTimeout(this.timeOutId);

      // 詳細画面表示用データを取得する
      const responsePlantsData = await getSummaryData(
        this.webApiModel,
        this.displayData
      );
      const responseScoreData = await getDisplayScore(
        this.webApiModel,
        this.displayData
      );
      // エラーの場合はエラーを表示し処理を中断する
      if (responsePlantsData === undefined) {
        // ローディング表示オフ
        this.isLoadingDisplay = false;
        if (store.getters.isAutoUpdate === true) {
          // 自動更新の場合はエラーをスナックバー表示する
          this.errorSnackBarMessage = "情報の取得に失敗しました";
        } else {
          // 自動更新でない場合は(ヘッダ内の)データ表示日時を
          // 確認する。これが空でない場合は、以下の場合に備えて
          // store内のonceUpdateDataTimeへ値をコピーする。
          // ・再読み込み操作をされた時
          // ・詳細版画面へ遷移する操作をされた時
          // ↓
          // いずれもonceUpdateDataTimeの日時を元にAPIを
          // アクセスしてデータ取得するので、ヘッダ内の表示と
          // APIアクセスの日時が一致していないと不自然である。
          const displayDataTime: DisplayDataTime =
            store.getters.displayDataTime;
          if (
            displayDataTime.date !== "" &&
            displayDataTime.hour !== "" &&
            displayDataTime.minute !== ""
          ) {
            store.commit("setOnceUpdateDataTime", displayDataTime);
          }
          // 自動更新でない場合はエラーポップアップを表示する
          this.errorDialogMessage = "情報の取得に失敗しました";
          this.showErrorDialog = true;
        }
        debugLog("getDisplayData error");
      } else {
        // 時刻オフセットを考慮したdayjsのオブジェクトを作成する
        const timeOffset: number = store.getters.companies_plant.time_offset;
        const recordTime = dayjs(this.displayData.record_time).utcOffset(
          timeOffset
        );
        debugLog("recordTime with offset:", recordTime.format());
        // データ表示日時を設定する
        const displayDataTime = {} as DisplayDataTime;
        displayDataTime.date = dayjs(recordTime).format("YYYY-MM-DD");
        displayDataTime.hour = dayjs(recordTime).format("HH");
        displayDataTime.minute = dayjs(recordTime).format("mm");
        store.commit("setDisplayDataTime", displayDataTime);

        // 成功すればエラーのスナックバー表示を消去
        this.errorSnackBarMessage = "";
        // ローディング表示オフ
        this.isLoadingDisplay = false;
      }
      // エラー発生有無によらず、自動更新の場合は、自動更新用のタイマーを設定する
      if (store.getters.isAutoUpdate === true) {
        // 画面更新間隔(秒)をstoreから取得
        const refresh_seconds = store.getters.refresh_seconds;
        this.timeOutId = window.setTimeout(() => {
          // タイマー発火時、自動更新の場合は画面表示更新を行う
          if (store.getters.isAutoUpdate === true) {
            debugLog("自動更新実施");
            this.getDisplayData();
          }
        }, refresh_seconds * 1000);
      }
    },
    // エラーポップアップを閉じる
    resetErrorDialog(): void {
      this.errorDialogMessage = "";
      this.showErrorDialog = false;
    },
  },
});
